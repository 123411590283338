import React, { useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { useMutation, useQueryClient } from 'react-query';
import { useRole } from 'hooks/useRole';
import { useAuth } from 'hooks/useAuth';
import moment from "moment";
import Apaexlinecolumn from "../../AllCharts/apex/apaexlinecolumn"
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import MiniWidget from "./mini-widget";
import { getClaimStatusCountsApi } from 'api/report';
import { setClaimSummary } from 'store/actions'

//redux
import { useSelector, useDispatch } from "react-redux";
import { getSearchColumns, sumArray } from 'utils/tools';
import MainFilter from "components/Common/MainFilter";

//Members Chart
const series1 = [
  { name: "Members", data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14] },
]
const options1 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#f1b44c"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: false }, x: { show: false }, marker: { show: false } },
}

//Dependants Chart
const series2 = [
  { name: "Dependants", data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54] },
]
const options2 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#00b3e4"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: false }, x: { show: false }, marker: { show: false } },
}

//Defaulted Chart
const series3 = [
  { name: "Defaulted", data: [35, 53, 93, 47, 54, 24, 47, 75, 65, 19, 14] },
]
const options3 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#50a5f1"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: false }, x: { show: false }, marker: { show: false } },
}

const ClaimReports = () => {
  const { isFacilitator, isSiteCord, isCountyCoordinator, isClusterLeader, isFinance, isDirector } = useRole();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const {
    claimSummary,
  } = useSelector((state) => ({
    claimSummary: state?.reports?.claims,
  }));

  const reports = [
    {
      title: "Claims",
      icon: "mdi mdi-account",
      color: "primary",
      value: sumArray(claimSummary, 'totalClaims'),
      desc: "Total Claims",
      series: series1,
      options: options1,
      arrowUpDown: 'mdi mdi-arrow-up ms-1 text-success'
    },
    {
      title: "Processed",
      icon: "mdi mdi-account-multiple",
      color: "info",
      value: sumArray(claimSummary, 'totalProcessed'),
      desc: "Processed Claims",
      series: series2,
      options: options2,
      arrowUpDown: 'mdi mdi-arrow-down ms-1 text-danger'
    },
    {
      title: "Declined",
      icon: "mdi mdi-account-alert",
      color: "warning",
      value: sumArray(claimSummary, 'totalDeclined'),
      desc: "Declined Claims",
      series: series3,
      options: options3,
      arrowUpDown: 'mdi mdi-arrow-up ms-1 text-success'
    },
  ];

  const columnChartSeries = [
    {
      name: "Total Claims",
      data: claimSummary?.map(c => c.totalClaims),
    },
    {
      name: "Processed",
      data: claimSummary?.map(c => c.totalProcessed),
    },
    {
      name: "Declined",
      data: claimSummary?.map(c => c.totalDeclined),
    },
  ];

  const { mutate: mutateClaimCounts,
    data } = useMutation(
      (payload) => getClaimStatusCountsApi(payload),
      {
        onSuccess: res => {
          dispatch(setClaimSummary(res?.data));
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  useEffect(() => {
    mutateClaimCounts({
      fromDate: moment().startOf('month').format('DD-MM-YYYY'),
      toDate: moment().format('DD-MM-YYYY'),
      address: {
      }
    });
  }, []);

  const onSearch = (data) => {
    const addressData = {};
    if (isFacilitator) {
      addressData["wardId"] = address?.wardId;
    }
    if (isSiteCord && data?.wardId) {
      addressData["wardId"] = data?.wardId;
    }
    if (isCountyCoordinator || isClusterLeader || isFinance || isDirector) {
      if (data?.wardId) {
        addressData["wardId"] = data?.wardId.value;
      }
      if (data?.constituencyId) {
        addressData["constituencyId"] = data?.constituencyId.value;
      }
      if (data?.countyId) {
        addressData["countyId"] = data?.countyId.value;
      }
      if ((isClusterLeader || isFinance || isDirector) && data?.regionId) {
        addressData["regionId"] = data?.regionId.value;
      }
    }
    mutateClaimCounts({
      fromDate: data?.startDate?.value,
      toDate: data?.endDate?.value,
      wardIds: getSearchColumns()?.find(t => t?.name === 'wardIds')?.value,
      address: addressData
    });
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Reports" breadcrumbItem="Claim Reports" />
        <MainFilter onSearch={onSearch} showDateFilter />
        <Row>
          <MiniWidget reports={reports} />
        </Row>
        <Row>
          <Col xl={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4"> Claim Summary </CardTitle>
                <Apaexlinecolumn dataColors='["--bs-info","--bs-success","--bs-danger"]' series={columnChartSeries} categories={claimSummary?.map(c => c.monthName)} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ClaimReports
