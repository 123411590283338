import React, { useState, useEffect } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  FormFeedback,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
} from "reactstrap";

import { Link } from "react-router-dom"
import { getGroupDetailsByIdApi } from 'api/group';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from "react-router-dom";
import { getAllRegionsApi, getAllCountiesByRegionApi, getConstituencyApi, getAllWardsApi, getAllRelationshipsApi } from 'api/report';
import { addGroup, updateGroup } from 'api/group';
import { useRole } from 'hooks/useRole';
import Swal from "sweetalert2";

const AddGroup = () => {
  const { id } = useParams();
  const { isFacilitator, isSiteCord, address } = useRole();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  //meta title
  document.title = "Form Wizard | SUN Welfare Administration System";

  const { mutate: mutateGroup,
    isLoading: isLoadingGroup,
    data: group, } = useMutation(
      (payload) => getGroupDetailsByIdApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: group?.name || '',
      groupNumber: group?.groupNumber || '',
      description: group?.description || '',
      village: group?.village || '',
      regionId: group?.address?.regionId || '',
      countyId: group?.address?.countyId || '',
      constituencyId: group?.address?.constituencyId || '',
      wardId: group?.address?.wardId || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Group Name"),
      groupNumber: Yup.string().required("Please Enter Group Number"),
      regionId: Yup.string().required("Please Select Region"),
      countyId: Yup.string().required("Please Select County"),
      constituencyId: Yup.string().required("Please Select SubCounty"),
      wardId: Yup.string().required("Please Select Ward"),
    }),

    onSubmit: async (values) => {
      let groupData = {
        name: values.name,
        groupNumber: values.groupNumber,
        description: values.description,
        village: values.village,
        address: {
          wardId: values.wardId,
          constituencyId: values.constituencyId,
          countyId: values.countyId,
          regionId: values.regionId,
          village: values.village,
        }
      }
      let resp;
      if(id){
        groupData['id'] = id;
        resp = await updateGroup(groupData)
      }else{
        resp = await addGroup(groupData)
      }

      if (resp.status === "Success") {
        Swal.fire({
          icon: 'success',
          title: 'Group saved successfully',
          confirmButtonText: 'OK'
        }).then(() => {
          navigate(-1);
        });
        navigate(-1);
      } else {
        // Handle error case if needed
        Swal.fire({
          icon: 'error',
          title: 'Error saving group',
          text: res.message || 'An error occurred. Please try again later.',
        });
      }
    },
  });

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const { mutate: mutateRegions,
    isLoading: isLoadingRegions,
    data: regionsData, } = useMutation(
      (payload) => getAllRegionsApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutateCounty,
    isLoading: isLoadingCounty,
    data: countiesData, } = useMutation(
      (payload) => getAllCountiesByRegionApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutateSubCounty,
    isLoading: isLoadingSubCounty,
    data: subCountiesData, } = useMutation(
      (payload) => getConstituencyApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutateWards,
    isLoading: isLoadingWards,
    data: wardsData, } = useMutation(
      (payload) => getAllWardsApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

    useEffect(() => {
      if(id) mutateGroup(id);
  
    }, [id]);

  useEffect(() => {
    mutateRegions({ countryId: 1 });

    (group?.address?.regionId || address?.regionId) && mutateCounty({
      regionId: group?.address?.regionId || address?.regionId
    });

    (group?.address?.countyId || address?.countyId) && mutateSubCounty({
      countyId: group?.address?.countyId || address?.countyId
    });

    (group?.address?.constituencyId || address?.constituencyId) && mutateWards({
      constituencyId: group?.address?.constituencyId || address?.constituencyId
    });
  }, [group]);

  const handleRegionChange = e => {
    const regionId = e.target.value;
    validation.setFieldValue('regionId', regionId);
    mutateCounty({
      regionId
    });
  }

  const handleCountyChange = e => {
    const countyId = e.target.value;
    validation.setFieldValue('countyId', countyId);
    mutateSubCounty({
      countyId
    });
  }

  const handleSubCountyChange = e => {
    const constituencyId = e.target.value;
    validation.setFieldValue('constituencyId', constituencyId);
    mutateWards({
      constituencyId
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Groups" breadcrumbItem="New Group" />

          <Row>
            <Link to="/groups">
              <Button type="button" color="primary" className="btn">
                <i className="bx bx-arrow-back"></i> Back
              </Button>
            </Link>
            <Col md="7">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="card-title mb-4">Create New SUN Group</h4>
                  </div>
                  <Form autoComplete="off" onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  >
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="name">
                            Group Name
                          </Label>
                          <Input
                            type="text"
                            name="name"
                            className="form-control"
                            id="name"
                            placeholder="Enter Group Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name ? true : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="groupNumber">
                            Group Number
                          </Label>
                          <Input
                            type="text"
                            name="groupNumber"
                            className="form-control"
                            id="groupNumber"
                            onChange={validation.handleChange}
                            placeholder="Enter Group Number"
                            onBlur={validation.handleBlur}
                            value={validation.values.groupNumber || ""}
                            invalid={
                              validation.touched.groupNumber && validation.errors.groupNumber ? true : false
                            }
                          />
                          {validation.touched.groupNumber && validation.errors.groupNumber ? (
                            <FormFeedback type="invalid">{validation.errors.groupNumber}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="village">
                            Village
                          </Label>
                          <Input
                            type="text"
                            name="village"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.village || ""}
                            className="form-control"
                            id="basicpill-phoneno-input3"
                            placeholder="Enter Village Name"
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="description">
                            Description
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            name="description"
                            id="basicpill-phoneno-input3"
                            placeholder="Enter Description"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label htmlFor="regionId">Select Region</Label>
                          <Input
                            name="regionId"
                            type="select"
                            className="select2"
                            placeholder="Select Region"
                            onChange={handleRegionChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.regionId || ""}
                            invalid={
                              validation.touched.regionId && validation.errors.regionId ? true : false
                            }
                          >
                            <option key={''}>Select Region</option>
                            {regionsData && regionsData.map(({ id, name }) =>
                              <option value={id} key={id}>{name}</option>
                            )}
                          </Input>
                          {validation.touched.regionId && validation.errors.regionId ? (
                            <FormFeedback type="invalid">{validation.errors.regionId}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Label htmlFor="role">Select County</Label>
                          <Input
                            name="countyId"
                            type="select"
                            className="select2"
                            placeholder="Select County"
                            onChange={handleCountyChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.countyId || ""}
                            invalid={
                              validation.touched.countyId && validation.errors.countyId ? true : false
                            }
                          >
                            <option key={''}>Select County</option>
                            {countiesData && countiesData.map(({ id, name }) =>
                              <option value={id} key={id}>{name}</option>
                            )}
                          </Input>
                          {validation.touched.countyId && validation.errors.countyId ? (
                            <FormFeedback type="invalid">{validation.errors.countyId}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Label htmlFor="role">Select SubCounty</Label>
                          <Input
                            name="constituencyId"
                            type="select"
                            className="select2"
                            placeholder="Select SubCounty"
                            onChange={handleSubCountyChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.constituencyId || ""}
                            invalid={
                              validation.touched.constituencyId && validation.errors.constituencyId ? true : false
                            }
                          >
                            <option key={''}>Select Sub-County</option>
                            {subCountiesData && subCountiesData.map(({ id, name }) =>
                              <option value={id} key={id}>{name}</option>
                            )}
                          </Input>
                          {validation.touched.constituencyId && validation.errors.constituencyId ? (
                            <FormFeedback type="invalid">{validation.errors.constituencyId}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Label htmlFor="role">Select Ward</Label>
                          <Input
                            name="wardId"
                            type="select"
                            className="select2"
                            placeholder="Select Ward"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.wardId || ""}
                            invalid={
                              validation.touched.wardId && validation.errors.wardId ? true : false
                            }
                          >
                            <option key={''}>Select Ward</option>
                            {wardsData && wardsData.map(({ id, name }) =>
                              <option value={id} key={id}>{name}</option>
                            )}
                          </Input>
                          {validation.touched.wardId && validation.errors.wardId ? (
                            <FormFeedback type="invalid">{validation.errors.wardId}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="text-end">
                      <Col md="12">
                        <Button color="primary" block type="submit">SUBMIT GROUP</Button>
                      </Col>
                    </Row>
                  </Form>
                  <Modal isOpen={showPopup} toggle={handleClosePopup}>
                    <ModalBody>
                      Group saved successfully.
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={handleClosePopup}>
                        OK
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddGroup
