import _, { isEmpty, reduce } from 'lodash';
import { ROLES } from 'configs/constants';

export const getSearch = (filter) => {
  const params = {
    limit: filter?.limit,
    offset: filter?.offset,
    q: filter?.q,
    orderBy: filter?.orderBy,
    ...getValidData(filter?.filter),
  };

  return convertObjToSearchStr(params);
};

export const downloadImg = async ({ url, fileName }) => {
  fetch(url, {
    method: 'GET',
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then((buffer) => {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName || url);
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch(() => {
      //
    });
};

export const convertObjToSearchStr = (params) =>
  Object.keys(params)
    .map((key) =>
      params[key]
        ? `${encodeURIComponent(key)}=${encodeURIComponent(
          JSON.stringify(params[key]),
        )}`
        : '',
    )
    .filter((data) => data !== '')
    .join('&');

const getValidDataOfObj = (obj, isFilter) => {
  const validData = reduce(
    obj,
    (result, value, key) => {
      if (Array.isArray(value)) {
        return value.length > 0 ? { ...result, [key]: value } : result;
      }
      if (typeof value === 'object' && !isEmpty(value)) {
        const formatChildValue = getValidDataOfObj(value);
        return !isEmpty(formatChildValue)
          ? { ...result, [key]: formatChildValue }
          : result;
      }

      if (value || value === false || value === 0) {
        // eslint-disable-next-line
        result[key] = value;
        return { ...result, [key]: value };
      }

      if (value === '' && !isFilter) {
        // eslint-disable-next-line
        result[key] = '';
      }
      return result;
    },
    {},
  );
  return validData;
};

export const getValidData = (filter, isFilter) =>
  getValidDataOfObj(filter, isFilter);

export const getFilterFromUrl = (searchStr) => {
  const parsed = {};
  if (!searchStr || searchStr.trim() === '') return {};
  decodeURIComponent(searchStr)
    .trim()
    .substring(searchStr?.[0] === '?' ? 1 : 0)
    .split('&')
    .forEach((text) => {
      const keyValue = text.split('=');
      // eslint-disable-next-line
      parsed[keyValue[0]] = keyValue[1];
      try {
        parsed[keyValue[0]] = JSON.parse(parsed[keyValue[0]]);
      } catch (error) {
        // eslint-disable-next-line
        parsed[keyValue[0]] = parsed[keyValue[0]];
      }
    });

  // const parseToObj = zipObjectDeep(Object.keys(parsed), _.values(parsed));

  // delete parsed.limit;
  // delete parsed.offset;
  // delete parsed.order_by;
  // delete parsed.page;
  // delete parsed.q;
  return parsed;
};

export const replaceAll = (str, search, replacement) =>
  str.replace(new RegExp(search, 'g'), replacement);

export const makeBreadCrumbFromPath = (location) => {
  const BREADCRUMB_LIST = [];
  const paths = location.pathname.split('/');
  paths.forEach((data) => {
    if (data === '') return;
    BREADCRUMB_LIST.push({
      title: data,
      path: `${BREADCRUMB_LIST.length
        ? BREADCRUMB_LIST[BREADCRUMB_LIST.length - 1].path
        : ''
        }/${data}`,
    });
  });
  return BREADCRUMB_LIST;
};

export const getImageUrl = (image = '') =>
  (image && (image?.indexOf('http') > -1 || image?.indexOf('https') > -1)) ||
    image?.indexOf('data:image') > -1
    ? image
    : `${process.env.REACT_APP_IMAGE_HOST}${image}`;

export function plainToFlattenObject(object) {
  const result = {};

  function flatten(obj, prefix = '') {
    _.forEach(obj, (value, key) => {
      if (_.isObject(value) || Array.isArray(value)) {
        flatten(value, `${prefix}${key}.`);
      } else {
        result[`${prefix}${key}`] = value;
      }
    });
  }

  flatten(object);

  return result;
}

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export const downloadImage = (previewImage, fileName = 'product.png') => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = previewImage;
  a.style = 'display: none';
  a.download = fileName;
  a.click();
  a.remove();
};

export const getPreviewImageUrl = ({
  image = '',
  configs = {},
  ratio,
  width = 400,
}) => {
  const resizeByConvert = {
    bucket: configs?.BUCKET_NAME,
    key: image,
    edits: {
      resize: {
        width,
        fit: 'cover',
      },
    },
  };

  const resizeByRatio = {
    bucket: configs?.BUCKET_NAME,
    key: image,
    edits: {
      resize: {
        ratio,
      },
    },
  };
  const objJsonStr = JSON.stringify(ratio ? resizeByRatio : resizeByConvert);
  const objJsonB64 = btoa(objJsonStr);
  return (image &&
    (image.indexOf('http') > -1 || image.indexOf('https') > -1)) ||
    image.indexOf('data:image') > -1
    ? image
    : `${configs?.CDN_URL}/${objJsonB64}`;
};

export const formatInputMoney = {
  formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  parser: (value) => value.replace(/\\s?|(,*)/g, ''),
};

export const formatMoney = (number, n = 0, x = 3) => {
  const re = `\\d(?=(\\d{${x}})+${n > 0 ? '\\.' : '$'})`;
  return `${Number(number)
    // eslint-disable-next-line
    .toFixed(Math.max(0, ~~n))
    .replace(new RegExp(re, 'g'), '$&,')} KES`;
};

export const formatNumber = (number = 0, fixed = 2, n, x) => {
  const UNIT = ['', 'K', 'M'];
  let unitRank = 0;
  let tmpPrice = Math.abs(number);
  for (; ;) {
    tmpPrice = Number(tmpPrice) / 1000;
    unitRank += tmpPrice > 1 ? 1 : 0;
    if (tmpPrice < 1) break;
  }
  const re = `\\d(?=(\\d{${x || 3}})+${n > 0 ? '\\.' : '$'})`;
  return `${number >= 0 ? '' : '-'}${Number(tmpPrice * 1000)
    .toFixed(fixed)
    .replace(new RegExp(re, 'g'), '$&,')}${UNIT[unitRank] || ''}`;
};

export const numberWithCommas = (number = 0, fixed = 0) => number.toFixed(fixed).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

export const upperFirstChar = (str) =>
  `${str?.[0]?.toUpperCase() || ''}${str?.substring(1)?.toLowerCase() || ''}`;

export const lowerAllChar = (str) =>
  `${str?.toLowerCase() || ''}`;

export const sumArray = (arr, key) => {
  return (arr && arr.length) ? arr.reduce((a, b) => a + (b[key] || 0), 0) : 0;
};

export const getSearchColumns = () => {
  const role = localStorage.getItem('shofco_role');
  const addresses = JSON.parse(localStorage.getItem('addresses'));
  if (!role || !addresses) {
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('shofco_username');
    localStorage.removeItem('shofco_email');
    localStorage.removeItem('shofco_role');
    localStorage.removeItem('shofco_role_id');
    localStorage.removeItem('addresses');
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
    localStorage.removeItem('appUserId');
    localStorage.removeItem('appUserId');
    localStorage.removeItem('memberId');
    return [];
  }

  return [
    ...(role === ROLES.Facilitator ? [
      {
        "name": "wardIds",
        "value": addresses?.map(a => a.wardId)
      }] : []),
    ...(role === ROLES.CountyCoordinator ? [
      {
        "name": "countyId",
        "value": addresses.map(a => a.countyId)
      }] : []),
    ...(role === ROLES.ClusterLeader ? [
      {
        "name": "regionId",
        "value": addresses.map(a => a.regionId)
      }] : []),
    ...(role === ROLES.SiteCoordinator ? [
      {
        "name": "constituencyId",
        "value": addresses.map(a => a.constituencyId)
      }] : []),
  ]
}