import React, { useEffect, useState } from 'react';
import { Table, Row, Col, UncontrolledTooltip, Button } from "reactstrap"
import { numberWithCommas, upperFirstChar, lowerAllChar } from 'utils/tools';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRole } from 'hooks/useRole';
import { useAuth } from 'hooks/useAuth';
// import ProjectionFilter from '../ProjectionFilter';
import moment from "moment";
import { sumArray, getSearchColumns } from 'utils/tools'
import { getMemberStatusCountsApi, getClaimStatusCountsApi, getGroupStatusCountsApi, getPaymentsStatusCountsApi } from 'api/report';
import MainFilter from "components/Common/MainFilter";

const MapInformation = ({
  isLoading,
  county
}) => {
  const { isAuthenticated } = useAuth();
  const { isFacilitator, isSiteCord, isCountyCoordinator, isClusterLeader, isFinance, isDirector } = useRole();
  const [address, setAddress] = useState({})

  const [filterDates, setFilterDates] = useState({
    fromDate: moment().startOf('month').format('DD-MM-YYYY'),
    toDate: moment().format('DD-MM-YYYY'),
  })
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: mutateClaimCounts,
    data: claimSummary } = useMutation(
      (payload) => getClaimStatusCountsApi(payload),
      {
        onSuccess: res => {

        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutateMemberCounts,
    isLoading: isLoadingMembers,
    data: memberSummary } = useMutation(
      (payload) => getMemberStatusCountsApi(payload),
      {
        onSuccess: res => {
          // dispatch(setMemberSummary(res?.data));
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutateGroupCounts,
    data: groupSummary, } = useMutation(
      (payload) => getGroupStatusCountsApi(payload),
      {
        onSuccess: res => {
          // dispatch(setGroupSummary(res));
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutatePaymentsCounts,
    isLoading: isLoadingProducts,
    data: paymentSummary } = useMutation(
      (payload) => getPaymentsStatusCountsApi(payload),
      {
        onSuccess: res => {
          // dispatch(setPaymentSummary(res?.data));
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  useEffect(() => {
    if (county?.id) {
      address['countyId'] = county?.id;
      mutateClaimCounts({
        ...filterDates,
        wardIds: getSearchColumns()?.find(t => t.name === 'wardIds')?.value,
        address: {}
      });
      mutateMemberCounts({
        ...filterDates,
        wardIds: getSearchColumns()?.find(t => t.name === 'wardIds')?.value,
        address: {}
      });
      mutateGroupCounts(
        {
          ...filterDates,
          wardIds: getSearchColumns()?.find(t => t.name === 'wardIds')?.value,
          address: {}
        }
      );
      mutatePaymentsCounts({
        ...filterDates,
        wardIds: getSearchColumns()?.find(t => t.name === 'wardIds')?.value,
        address: {}
      });
    }

  }, [county]);

  const onSearch = (data) => {
    const addressData = {};
    if (isFacilitator) {
      addressData["wardId"] = address?.wardId;
    }
    if (isSiteCord && data?.wardId) {
      addressData["wardId"] = data?.wardId;
    }
    if (isCountyCoordinator || isClusterLeader || isFinance || isDirector) {
      if (data?.wardId) {
        addressData["wardId"] = data?.wardId.value;
      }
      if (data?.constituencyId) {
        addressData["constituencyId"] = data?.constituencyId.value;
      }
      if (data?.countyId) {
        addressData["countyId"] = data?.countyId.value;
      }
      if ((isClusterLeader || isFinance || isDirector) && data?.regionId) {
        addressData["regionId"] = data?.regionId.value;
      }
    }
    mutateClaimCounts({
      fromDate: data?.startDate?.value,
      toDate: data?.endDate?.value,
      wardIds: getSearchColumns()?.find(t => t.name === 'wardIds')?.value,
      address: addressData
    });
    mutateMemberCounts({
      fromDate: data?.startDate?.value,
      toDate: data?.endDate?.value,
      wardIds: getSearchColumns()?.find(t => t.name === 'wardIds')?.value,
      address: addressData
    });
    mutateGroupCounts({
      fromDate: data?.startDate?.value,
      toDate: data?.endDate?.value,
      wardIds: getSearchColumns()?.find(t => t.name === 'wardIds')?.value,
      address: addressData
    });
    mutatePaymentsCounts({
      fromDate: data?.startDate?.value,
      toDate: data?.endDate?.value,
      wardIds: getSearchColumns()?.find(t => t.name === 'wardIds')?.value,
      address: addressData
    });
  }

  return (
    <Row className="info-row">
      {/* {isLoading && (
        <div className="overlay-loading">
          <Spin />
        </div>
      )} */}
      <MainFilter onSearch={onSearch} showDateFilter />
      <Row className="m-3">
        {/* <ProjectionFilter onChangePagination={onChangePagination} />{' '} */}
        <Col md={6} className="fs-5 fw-bold">
          {`${t('address.countyName')}:`}
        </Col>
        <Col md={6} className="fw-bold fs-5 primary-text-blue">
          {upperFirstChar(county?.name || '')}{' '}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="fw-bold fs-5">
            Members Summary
          </h3>
          <Row className="m-1">
            <Col className="w-bold fs-6">
              {`${t('reports.totalMembers')}:`}
            </Col>
            <Col className="w-bold fs-6 primary-text-blue">
              {numberWithCommas(sumArray(memberSummary?.data, 'totalMembers') || 0)}
            </Col>
          </Row>
          <Row className="m-1">
            <Col span={6} className="w-bold fs-6">
              {`${t('reports.activeMembers')}:`}
            </Col>
            <Col span={6} className="w-bold fs-6 primary-text-blue">
              {numberWithCommas(sumArray(memberSummary?.data, 'totalActiveMembers') || 0)}
            </Col>
          </Row>
          <Row className="m-1">
            <Col span={6} className="w-bold fs-6">
              {`${t('reports.defaultMembers')}:`}
            </Col>
            <Col span={6} className="w-bold fs-6 primary-text-blue">
              {numberWithCommas(sumArray(memberSummary?.data, 'totalDefaultMembers') || 0)}
            </Col>
          </Row>
          <Row className="m-1">
            <Col span={6} className="w-bold fs-6">
              {`${t('reports.dormantMembers')}:`}
            </Col>
            <Col span={6} className="w-bold fs-6 primary-text-blue">
              {numberWithCommas(sumArray(memberSummary?.data, 'dormantMembers') || 0)}
            </Col>
          </Row>
          <Row className="m-1">
            <Col span={6} className="w-bold fs-6">
              {`${t('reports.pendingMembers')}:`}
            </Col>
            <Col span={6} className="w-bold fs-6 primary-text-blue">
              {numberWithCommas(sumArray(memberSummary?.data, 'totalPendingMembers') || 0)}
            </Col>
          </Row>
          <Row className="m-1">
            <Col span={6} className="w-bold fs-6">
              {`${t('reports.female')}:`}
            </Col>
            <Col span={6} className="w-bold fs-6 primary-text-blue">
              {numberWithCommas(sumArray(memberSummary?.data, 'totalFemaleMembers') || 0)}
            </Col>
          </Row>
          <Row className="m-1">
            <Col span={6} className="w-bold fs-6">
              {`${t('reports.male')}:`}
            </Col>
            <Col span={6} className="w-bold fs-6 primary-text-blue">
              {numberWithCommas(sumArray(memberSummary?.data, 'totalMaleMembers') || 0)}
            </Col>
          </Row>
          <Row className="m-1">
            <Col span={6} className="w-bold fs-6">
              {`${t('reports.totalDependents')}:`}
            </Col>
            <Col span={6} className="w-bold fs-6 primary-text-blue">
              {numberWithCommas(sumArray(memberSummary?.data, 'totalDependents') || 0)}
            </Col>
          </Row>
        </Col>
        <Col>
          <h3 className="fw-bold fs-5">
            Claims Summary
          </h3>
          <Row className="m-1">
            <Col span={6} className="w-bold fs-6">
              {`${t('reports.totalClaims')}:`}
            </Col>
            <Col span={6} className="w-bold fs-6 primary-text-blue">
              {numberWithCommas(sumArray(claimSummary?.data, 'totalClaims') || 0)}
            </Col>
          </Row>
          <Row className="m-1">
            <Col span={6} className="w-bold fs-6">
              {`${t('reports.approvedClaims')}:`}
            </Col>
            <Col span={6} className="w-bold fs-6 primary-text-blue">
              {numberWithCommas(sumArray(claimSummary?.data, 'totalProcessed') || 0)}
            </Col>
          </Row>
          <Row className="m-1">
            <Col span={6} className="w-bold fs-6">
              {`${t('reports.pendingClaims')}:`}
            </Col>
            <Col span={6} className="w-bold fs-6 primary-text-blue">
              {numberWithCommas(sumArray(claimSummary?.data, 'totalPending') || 0)}
            </Col>
          </Row>
          <Row className="m-1">
            <Col span={6} className="w-bold fs-6">
              {`${t('reports.rejectedClaims')}:`}
            </Col>
            <Col span={6} className="w-bold fs-6 primary-text-blue">
              {numberWithCommas(sumArray(claimSummary?.data, 'totalDeclined') || 0)}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <h3 className="fw-bold fs-5">
            Payments Summary
          </h3>
          <Row className="m-1">
            <Col span={6} className="w-bold fs-6">
              {`${t('reports.totalPremiums')}:`}
            </Col>
            <Col span={6} className="w-bold fs-6 primary-text-blue">
              {numberWithCommas(sumArray(paymentSummary, 'totalPremiums') || 0)}
            </Col>
          </Row>
          <Row className="m-1">
            <Col span={6} className="w-bold fs-6">
              {`${t('reports.defaultPremiums')}:`}
            </Col>
            <Col span={6} className="w-bold fs-6 primary-text-blue">
              {numberWithCommas(sumArray(paymentSummary, 'defaultPremiums') || 0)}
            </Col>
          </Row>
        </Col>
        <Col>
          <h3 className="fw-bold fs-5">
            Groups Summary
          </h3>
          <Row className="m-1">
            <Col span={6} className="w-bold fs-6">
              {`${t('reports.totalGroups')}:`}
            </Col>
            <Col span={6} className="w-bold fs-6 primary-text-blue">
              {numberWithCommas(sumArray(groupSummary?.data, 'totalGroups') || 0)}
            </Col>
          </Row>
          <Row className="m-1">
            <Col span={6} className="w-bold fs-6">
              {`${t('reports.activeGroups')}:`}
            </Col>
            <Col span={6} className="w-bold fs-6 primary-text-blue">
              {numberWithCommas(sumArray(groupSummary?.data, 'totalActiveGroups') || 0)}
            </Col>
          </Row>
          <Row className="m-1">
            <Col span={6} className="w-bold fs-6">
              {`${t('reports.inactiveGroups')}:`}
            </Col>
            <Col span={6} className="w-bold fs-6 primary-text-blue">
              {numberWithCommas(sumArray(groupSummary?.data, 'totalInactiveGroups') || 0)}
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

// const MapInformationWrapper = styled(Row)`
//   position: relative;

//   .overlay-loading {
//     position: absolute;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background: #ffffff60;
//     z-index: 10;
//   }
//   .primary-text-yellow{
//     background: ${({ theme }) => theme.color.blue};
//     color: ${({ theme }) => theme.color.white};
//     outline: transparent;
//     border-style: none
//   }
// `;

export default MapInformation;
