import React, { useEffect, useState } from 'react';
import { Col, Form, FormGroup, Button, Input, Label, Row, TabPane } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from 'react-query';
import { useSelector, useDispatch } from "react-redux";
import { updateMember } from 'api/member';
import { getAllRegionsApi, getAllCountiesByRegionApi, getConstituencyApi, getAllWardsApi, getAllRelationshipsApi } from 'api/report';
import { getAllGroupApi } from 'api/group';
import { setMember, editMember } from "store/actions";
import { useRole } from 'hooks/useRole';

const AddressTab = ({ onNext, onChangeAddress, address, member }) => {
    const [regions, setRegions] = useState([]);
    const [counties, setCounties] = useState([]);
    const [wards, setWards] = useState([]);
    const [subCounties, setSubCounties] = useState([]);
    const { isAdmin, isSuperAdmin } = useRole();

    const showAddress = (isAdmin || isSuperAdmin);
    const dispatch = useDispatch();
    // const { member } = useSelector((state) => ({ member: state.member.member }));
    const queryClient = useQueryClient();

    const validationSchema = Yup.object({
        regionId: Yup.string().required("Please Select Region"),
        wardId: Yup.string().required("Please Select Ward"),
        constituencyId: Yup.string().required("Please Select Constituency"),
        countyId: Yup.string().required("Please Select County"),
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...member?.address,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            // Handle form submission logic here
            console.log(values);
            try {
                await validation.validateForm();
                const data = {
                    ...member,
                    address: { ...values },
                }

                await updateMember(data).then(res => {
                    console.log(res);
                }).catch(err => {
                    console.log(err);
                });

            } catch (error) {
                console.error("Form validation error:", error);
            }
        },
    });

    const { mutate: mutateRegions, isLoading: isLoadingRegions } = useMutation(
        (payload) => getAllRegionsApi(payload),
        {
            onSuccess: res => {
                let regionId = member?.address?.regionId || address?.regionId;
                setRegions(res?.filter(r => showAddress ? r.id : r.id === regionId));
            },
            onSettled: () => {
                queryClient.invalidateQueries('get-all-counties');
            },
        }
    );

    const { mutate: mutateCounty, isLoading: isLoadingCounty, data: countiesData } = useMutation(
        (payload) => getAllCountiesByRegionApi(payload),
        {
            onSuccess: res => {
                let countyId = member?.address?.countyId || address?.countyId;
                setCounties(res?.filter(r => showAddress ? r.id : r.id === countyId));
            },
            onSettled: () => {
                queryClient.invalidateQueries('get-all-counties');
            },
        }
    );

    const { mutate: mutateSubCounty, isLoading: isLoadingSubCounty, data: subCountiesData } = useMutation(
        (payload) => getConstituencyApi(payload),
        {
            onSuccess: res => {
                let constituencyId = member?.address?.constituencyId || address?.constituencyId;
                setSubCounties(res?.filter(r => showAddress ? r.id : r.id === constituencyId));
            },
            onSettled: () => {
                queryClient.invalidateQueries('get-all-counties');
            },
        }
    );

    const { mutate: mutateWards, isLoading: isLoadingWards, data: wardsData } = useMutation(
        (payload) => getAllWardsApi(payload),
        {
            onSuccess: res => {
                let wardId = member?.address?.wardId || address?.wardId;
                setWards(res?.filter(r => showAddress ? r.id : r.id === wardId));
            },
            onSettled: () => {
                queryClient.invalidateQueries('get-all-counties');
            },
        }
    );

    const { mutate: mutateGroups, isLoading: isLoadingGroups, data: groupsData } = useMutation(
        (payload) => getAllGroupApi(payload),
        {
            onSuccess: res => {
                console.log(res);
            },
            onSettled: () => {
                queryClient.invalidateQueries('get-all-counties');
            },
        }
    );

    useEffect(() => {
        mutateRegions({
            countryId: 1
        });
        if (member?.addresses) {
            const { regionId, countyId, constituencyId, wardId } = member?.addresses[0];
            regionId && mutateCounty({ regionId });
            countyId && mutateSubCounty({ countyId });
            constituencyId && mutateWards({ constituencyId });
        } else {
            console.log(address);
            const { regionId, countyId, constituencyId, wardId } = address;
            regionId && mutateCounty({ regionId });
            countyId && mutateSubCounty({ countyId });
            constituencyId && mutateWards({ constituencyId });
            // wardId && mutateGroups({
            //     pageSize: 1000,
            //     pageNumber: 0,
            //     sortBy: "name",
            //     searchColumns: [{ "name": "wardId", "value": wardId }]
            // });
        }

    }, [member]);

    const onChangeRegion = e => {
        const regionId = e.target.value;
        validation.setFieldValue('regionId', regionId);
        onChangeAddress({ regionId });
        mutateCounty({ regionId });
    }

    const onChangeCounty = e => {
        const countyId = e.target.value;
        validation.setFieldValue('countyId', countyId);
        onChangeAddress({ countyId });
        mutateSubCounty({ countyId });
    }

    const onChangeSubCounty = e => {
        const constituencyId = e.target.value;
        validation.setFieldValue('constituencyId', constituencyId);
        onChangeAddress({ constituencyId });
        mutateWards({ constituencyId });
    }

    const onChangeWard = e => {
        const wardId = e.target.value;
        validation.setFieldValue('wardId', wardId);
        onChangeAddress({ wardId });
        mutateGroups({
            pageSize: 99,
            pageNumber: 0,
            sortBy: "name",
            searchColumns: [{ "name": "wardId", "value": wardId }]
        });
    }

    const onChangeGroup = e => {
        const groupId = e.target.value;
        onChangeAddress({ groupId });
        // dispatch(editMember({ groupId }, 'address'));
        dispatch(editMember({ groupId }, 'member'));
        validation.setFieldValue('groupId', groupId);
    }

    return (
        <Form autoComplete="off" onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
        }}>
            <Row>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for='regionId'>Select Region</Label>
                        <Input type='select' className="form-select"
                            name="regionId"
                            id='regionId'
                            value={validation.values.regionId || ""}
                            onChange={onChangeRegion}>
                            <option defaultValue value={''}>
                                Select Region...
                            </option>
                            {
                                regions?.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                )
                                )
                            }
                        </Input>
                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for='countyId'>Select County</Label>
                        <Input type='select' className="form-select"
                            id='countyId'
                            name="countyId"
                            value={validation.values.countyId || ""}
                            onChange={onChangeCounty}>
                            <option defaultValue value={''}>
                                Select County...
                            </option>
                            {
                                counties?.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                )
                                )
                            }
                        </Input>
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for='constituencyId'>Select Constituency</Label>
                        <Input type='select' className="form-select"
                            name="constituencyId"
                            id='constituencyId'
                            value={validation.values.constituencyId || ""}
                            onChange={onChangeSubCounty}>
                            <option defaultValue value={''}>
                                Select Constituency...
                            </option>
                            {
                                subCounties?.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                )
                                )
                            }
                        </Input>
                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for='wardId'>Select Ward</Label>
                        <Input type='select' className="form-select"
                            id='wardId'
                            name="wardId"
                            value={validation.values.wardId || ""}
                            onChange={onChangeWard}>
                            <option defaultValue value={''}>
                                Select Ward...
                            </option>
                            {
                                wards?.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                )
                                )
                            }
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            {
                (isSuperAdmin || isAdmin) && member && <Row className="mt-4">
                    <Col md="6">
                        <FormGroup>
                            <Button
                                type="submit"
                                color="primary"
                                block
                            >
                                SAVE
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            }
            {/* <Row>
                <Col lg="12">
                    <FormGroup className="mb-3">
                        <Label for='groupId'>Select Group</Label>
                        <Input type='select'
                            id='groupId'
                            className="form-select"
                            name="groupId"
                            value={validation.values.groupId || ""}
                            onChange={onChangeGroup}>
                            <option defaultValue>
                                Select Group...
                            </option>
                            {
                                groupsData?.list.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))
                            }
                        </Input>
                    </FormGroup>
                </Col>
            </Row> */}
        </Form>
    )
}

export default AddressTab;
