import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from 'react-i18next';
import { getSearchColumns, sumArray } from 'utils/tools';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  addNewApplication as onAddNewApplication,
  updateApplication as onUpdateApplication,
  setApplicationSummary
} from "store/actions";

import {
  BillingName,
  Total,
  PaymentStatus
}
  from "./ApplicationCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import ApplicationModal from "./ApplicationModal";

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import { getNewApplicationsApi } from 'api/application';
import { useMutation, useQueryClient } from 'react-query';
import { useRole } from 'hooks/useRole';
import { useAuth } from 'hooks/useAuth';
import { getApplicationStatusCountsApi } from 'api/report';
import MainFilter from "components/Common/MainFilter";

function Application(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(0);

  const queryClient = useQueryClient();

  const {
    applicationSummary,
  } = useSelector((state) => ({
    applicationSummary: state.reports.applications,
  }));
  const { t } = useTranslation();

  const { isFacilitator, isSiteCord, isCountyCoordinator, isClusterLeader, isFinance, isDirector, isAdmin, isSuperAdmin, addresses } = useRole();


  const [addressData, setAddressData] = useState({})

  const dateFormat = 'DD-MM-YYYY';
  const [pagination, setPagination] = useState({
    pageSize: 100,
    pageNumber: 1,
    sortBy: 'id',
    ascendingSort: true,
    wardIds: getSearchColumns()?.find(t => t.name === 'wardIds')?.value,
    searchColumns: [
      {
        "name": "endDate",
        "value": moment().format(dateFormat)
      },
      {
        "name": "startDate",
        "value": moment().startOf('month').format(dateFormat)
      }
    ]
  });

  const { mutate: mutateApplications,
    isLoading: isLoadingApplications,
    data: applicationsData, } = useMutation(
      (payload) => getNewApplicationsApi(payload),
      {
        onSuccess: res => {
          res.pages > 0 && setPages(res.pages);
        },
        onSettled: () => {
          setLoading(false);
          queryClient.invalidateQueries('/um/workflow/search');
        },
      }
    );

  const { mutate: mutateApplicationCounts,
    data } = useMutation(
      (payload) => getApplicationStatusCountsApi(payload),
      {
        onSuccess: res => {
          dispatch(setApplicationSummary(res?.data));
        },
        onSettled: () => {
          queryClient.invalidateQueries('/report/application/status/counts');
        },
      }
    );

  const applicantsCardData = [
    {
      title: "Total Applications",
      iconClass: "bx bx-task",
      description: applicationSummary?.length ? sumArray(applicationSummary, 'totalApplications').toLocaleString() : 0
    },
    {
      title: "Approved", iconClass: "bx bx-check-circle",
      description: applicationSummary?.length ? sumArray(applicationSummary, 'totalApproved').toLocaleString() : 0
    },
    {
      title: "Rejected",
      iconClass: "bx bx-dislike",
      description: applicationSummary?.length ? sumArray(applicationSummary, 'totalRejected').toLocaleString() : 0
    },
    {
      title: "Pending",
      iconClass: "bx bx-block",
      description: applicationSummary?.length ? sumArray(applicationSummary, 'totalPending').toLocaleString() : 0
    },
  ];

  //meta title
  document.title = "Applications | SUN Welfare Administration System";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [application, setApplication] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      applicationId: (application && application.applicationId) || '',
      billingName: (application && application.billingName) || '',
      applicationdate: (application && application.applicationdate) || '',
      total: (application && application.total) || '',
      paymentStatus: (application && application.paymentStatus) || 'Paid',
      badgeclass: (application && application.badgeclass) || 'success',
      paymentMethod: (application && application.paymentMethod) || 'Mastercard',
    },
    validationSchema: Yup.object({
      applicationId: Yup.string()
        .matches(
          /[0-9\.\-\s+\/()]+/,
          "Please Enter Valid Application Id"
        ).required("Please Enter Your Application Id"),
      billingName: Yup.string().required("Please Enter Your Billing Name"),
      applicationdate: Yup.string().required("Please Enter Your Application Date"),
      total: Yup.string().matches(
        /[0-9\.\-\s+\/()]+/,
        "Please Enter Valid Amount"
      ).required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateApplication = {
          id: application ? application.id : 0,
          applicationId: values.applicationId,
          billingName: values.billingName,
          applicationdate: values.applicationdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update application
        dispatch(onUpdateApplication(updateApplication));
        validation.resetForm();
      } else {
        const newApplication = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          applicationId: values["applicationId"],
          billingName: values["billingName"],
          applicationdate: values["applicationdate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        };
        // save new application
        dispatch(onAddNewApplication(newApplication));
        validation.resetForm();
      }
      toggle();
    },
  });

  const toggleViewModal = () => setModal1(!modal1);

  useEffect(() => {
    mutateApplications({ ...pagination, pageNumber: pagination.pageNumber - 1 });
  }, [pagination]);

  useEffect(() => {
    mutateApplicationCounts({
      fromDate: moment().startOf('month').format('DD-MM-YYYY'),
      toDate: moment().format('DD-MM-YYYY'),
      address: addressData
    });
  }, [addressData]);

  const onChangePagination = (
    { page, limit }, filters, sorter) => {
    let searchColumns = isEmpty(filters) ? pagination.searchColumns : [
      ...pagination.searchColumns,
      ...(filters?.firstName ? [
        {
          "name": "firstName",
          "value": filters?.firstName
        }] : []),
      ...(filters?.lastName ? [
        {
          "name": "lastName",
          "value": filters?.lastName
        }] : []),
      ...(filters?.email ? [
        {
          "name": "email",
          "value": filters?.email
        }] : []),
      ...(filters?.applicationNumber ? [
        {
          "name": "applicationNumber",
          "value": filters?.applicationNumber
        }] : []),
      ...(filters?.mobileNumber1 ? [
        {
          "name": "mobileNumber1",
          "value": filters?.mobileNumber1
        }] : []),
    ];
    setPagination({ ...pagination, pageNumber: page || pagination.pageNumber, pageSize: limit || pagination.pageSize, searchColumns })
  }

  const onSearch = (data) => {
    const searchColumns = [];
    if (!isEmpty(data)) {
      if (isFacilitator) {
        searchColumns.push({ name: "wardId", value: address?.wardId });
      } else if (isSiteCord && data.wardId) {
        searchColumns.push(data?.wardId);
      } else {
        if ((isClusterLeader || isFinance || isDirector || isAdmin || isSuperAdmin) && data?.regionId) {
          searchColumns.push(data?.regionId);
        }
        if (data.wardId) {
          searchColumns.push(data?.wardId);
        }
        if (data.constituencyId) {
          searchColumns.push(data?.constituencyId);
        }
        if (data.countyId) {
          searchColumns.push(data?.countyId);
        }
      }
      searchColumns.push(data.startDate);
      searchColumns.push(data.endDate);
    }

    setPagination({ ...pagination, searchColumns });
    let add = {}
    searchColumns.forEach((a, i) => {
      add[a.name] = a.value;
    });
    setAddressData(add);
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      setApplication(null);
    } else {
      setModal(true);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => <div className="form-check font-size-16" >
          <input className="form-check-input" type="checkbox" id="checkAll" />
          <label className="form-check-label" htmlFor="checkAll"></label>
        </div>,
        accessor: '#',
        width: '20px',
        filterable: true,
        Cell: (cellProps) => (
          <div className="form-check font-size-16" >
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        )
      },
      {
        Header: 'ID',
        accessor: 'id',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
      },
      {
        Header: 'APPLICATION NO',
        accessor: 'applicationNumber',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        }
      },
      {
        Header: 'FULL NAME',
        accessor: (row) => `${row?.member?.firstName || ''} ${row?.member?.lastName || ''}`,
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        }
      },
      {
        Header: 'MOBILE',
        accessor: 'member.mobileNumber1',
        filterable: true,
        Cell: (cellProps) => {
          return <Total {...cellProps} />;
        }
      },
      {
        Header: 'APPLICATION DATE',
        accessor: 'applicationDate',
        filterable: true,
        Cell: (cellProps) => {
          const dateValue = cellProps.value;
          const formattedDate = dateValue ? moment(dateValue).format('YYYY MMM DD h:mm A') : '';
          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: 'SOURCE',
        accessor: 'source',
        filterable: true,
        Cell: (cellProps) => {
          return <Total {...cellProps} />;
        }
      },
      {
        Header: 'STATUS',
        accessor: 'status',
        filterable: true,
        Cell: (cellProps) => {
          return <PaymentStatus {...cellProps} />;
        }
      },
      {
        Header: 'WARD',
        accessor: 'member.address.wardName',
        filterable: true,
        Cell: (cellProps) => {
          return <Total {...cellProps} />;
        }
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <ApplicationModal isOpen={modal1} toggle={toggleViewModal} />
      {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteApplication}
        onCloseClick={() => setDeleteModal(false)}
      /> */}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Applications" />
          <Row>
            {/* Reports Render */}
            {applicantsCardData.map((report, key) => (
              <Col md="3" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                          {report.title}
                        </p>
                        <h4 className="mb-0">{report.description}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i
                            className={
                              "bx " + report.iconClass + " font-size-24"
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <MainFilter onSearch={onSearch} showDateFilter />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={applicationsData?.list || []}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    isPagination={true}
                    filterable={false}
                    hasSearch
                    showView
                    loading={isLoadingApplications}
                    pages={pages}
                    addText="New Application"
                    resource="application"
                    iscustomPageSizeOptions={true}
                    tableClass="align-middle table-nowrap table-check"
                    theadClass="table-light"
                    pagination="pagination pagination-rounded justify-content-end mb-2"
                    paginationData={pagination}
                    onChangePagination={onChangePagination}
                    searchByNames={[
                      {
                        value: 'applicationNumber',
                        text: t('applications.applicationNumber'),
                      },
                      {
                        value: 'firstName',
                        text: t('users.firstName'),
                      },
                      {
                        value: 'lastName',
                        text: t('users.lastName'),
                      },
                      {
                        value: 'mobileNumber1',
                        text: t('common.mobileNumber'),
                      },
                      {
                        value: 'email',
                        text: t('common.email'),
                      }
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* } */}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Application" : "Add Application"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Application Id</Label>
                      <Input
                        name="applicationId"
                        type="text"
                        placeholder="Insert Application Id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.applicationId || ""}
                        invalid={
                          validation.touched.applicationId && validation.errors.applicationId ? true : false
                        }
                      />
                      {validation.touched.applicationId && validation.errors.applicationId ? (
                        <FormFeedback type="invalid">{validation.errors.applicationId}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Billing  Name</Label>
                      <Input
                        name="billingName"
                        type="text"
                        placeholder="Insert Billing Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.billingName || ""}
                        invalid={
                          validation.touched.billingName && validation.errors.billingName ? true : false
                        }
                      />
                      {validation.touched.billingName && validation.errors.billingName ? (
                        <FormFeedback type="invalid">{validation.errors.billingName}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Application Date</Label>
                      <Flatpickr
                        className="form-control d-block"
                        name="applicationdate"
                        placeholder="Select time"
                        options={{
                          dateFormat: 'd M, Y',
                        }}
                        onChange={(date) => validation.setFieldValue("applicationdate", moment(date[0]).format("DD MMMM, YYYY"))}
                        value={validation.values.applicationdate}
                      />
                      {validation.touched.applicationdate && validation.errors.applicationdate ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.applicationdate}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Total</Label>
                      <Input
                        name="total"
                        type="text"
                        placeholder="Insert Total Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.total || ""}
                        invalid={
                          validation.touched.total && validation.errors.total ? true : false
                        }
                      />
                      {validation.touched.total && validation.errors.total ? (
                        <FormFeedback type="invalid">{validation.errors.total}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Status</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.paymentStatus || ""
                        }
                      >
                        <option>Paid</option>
                        <option>Chargeback</option>
                        <option>Refund</option>
                      </Input>
                      {validation.touched.paymentStatus && validation.errors.paymentStatus ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.paymentStatus}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Badge Class</Label>
                      <Input
                        name="badgeclass"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.badgeclass || ""}
                      >
                        <option>success</option>
                        <option>danger</option>
                        <option>warning</option>
                      </Input>
                      {validation.touched.badgeclass && validation.errors.badgeclass ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.badgeclass}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Method</Label>
                      <Input
                        name="paymentMethod"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.paymentMethod || ""
                        }
                      >
                        <option>Mastercard</option>
                        <option>Visa</option>
                        <option>Paypal</option>
                        <option>COD</option>
                      </Input>
                      {validation.touched.paymentMethod && validation.errors.paymentMethod ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.paymentMethod}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
Application.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default Application;


