import React, { Fragment, useEffect, useMemo, useState, useCallback } from "react"

import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useExpanded,
  usePagination,
} from "react-table";
import debounce from 'lodash/debounce'

import { useTranslation } from 'react-i18next';
import { Table, Row, Col, Input, UncontrolledTooltip, Button, Pagination, PaginationItem, Form, PaginationLink, FormGroup } from "reactstrap";
import { delApi } from 'api/crud';
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter"
import { useNavigate, Link, useLocation } from "react-router-dom";
import DeleteModal from './DeleteModal';
import Spinners from "components/Common/Spinner";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter setGlobalFilter={setGlobalFilter} />}
    </React.Fragment>
  )
}

const itemsPerPage = 10;

const TableContainer = ({
  addText = "Add New",
  showEdit,
  showDelete,
  showView,
  hasCreate,
  handleEdit,
  handleView,
  handleDelete,
  handleCreate,
  rootPath,
  columns,
  data,
  pages,
  resource,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSizeOptions,
  iscustomPageSizeOptions,
  isPagination,
  isShowingPageLength,
  paginationDiv,
  pagination,
  tableClass,
  loading,
  theadClass,
  searchByNames,
  hasExport,
  hasSearch,
  paginationData,
  onChangePagination,
  completeTransaction = false,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        hiddenColumns: columns?.filter(c => c.hidden)?.map(c => c?.accessor),
        pageIndex: 0,
        pageSize: paginationData.pageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  )
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchKey, setSearchKey] = useState('');

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    const pageSize = Number(event.target.value);
    const isSearch = (searchKey && searchValue);
    const search = {};
    if (isSearch) search[searchKey] = searchValue;
    onChangePagination({ limit: pageSize, page: paginationData.pageNumber }, search)
    setPageSize(pageSize)
  }

  const onCreate = () => {
    if (handleCreate) {
      handleCreate();
    } else {
      navigate(`${rootPath || location.pathname}/create`);
    }
  };
  const onCompleteTransaction = () => {
    navigate(`${rootPath || location.pathname}/complete-transaction`);
  };

  const onEdit = (record) => {
    if (handleEdit) {
      handleEdit(record.original);
    } else {
      navigate(`${rootPath || location.pathname}/${record.original.id}/edit`);
    }
  };

  const onView = (record) => {
    if (handleView) {
      handleView(record.original);
    } else {
      navigate(`${rootPath || location.pathname}/${record.original.id}`);
    }
  };

  const onDelete = (record) => {
    setId(record?.original?.id);
    setDeleteModal(true);
  };

  const deleteResource = async () => {
    if(handleDelete){
      await handleDelete(id);
    }else{
      await delApi(id).then(() => {
      })
    }
    setDeleteModal(false);
  }

  // const [currentPage, setCurrentPage] = useState(paginationData?.pageNumber || 1);
  const { pageNumber, pageSize: limit } = paginationData;

  const indexOfLastItem = pageNumber * limit;
  const indexOfFirstItem = indexOfLastItem - limit;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);


  const totalPages = Math.ceil(data.length / limit);

  const renderPaginationLinks = () => {
    const maxLinksToShow = 4; // Adjust the number of links to show

    let startPage = Math.max(pageNumber - Math.floor(maxLinksToShow / 2), 1);
    let endPage = Math.min(startPage + maxLinksToShow - 1, pages);

    // Handle edge case when endPage is less than maxLinksToShow
    if (endPage - startPage + 1 < maxLinksToShow) {
      startPage = Math.max(endPage - maxLinksToShow + 1, 1);
    }

    return [...Array(endPage - startPage + 1)].map((_, index) => (
      <PaginationItem key={startPage + index} active={pageNumber === startPage + index}>
        <PaginationLink onClick={() => paginate(startPage + index)}>
          {startPage + index}
        </PaginationLink>
      </PaginationItem>
    ));
  };

  const paginate = (pageNumber) => {
    const isSearch = (searchKey && searchValue);

    const search = {};
    if (isSearch) search[searchKey] = searchValue;
    onChangePagination({ limit: paginationData.pageSize, page: pageNumber }, search)
  };

  const makeDebounceSearch = useCallback(debounce((key, value) => {
    if (key && value) {
      onChangePagination({ limit: paginationData.pageSize, page: paginationData.pageNumber }, {
        [key]: value,
      })
    } else {
      onChangePagination({ limit: paginationData.pageSize, page: paginationData.pageNumber }, {
      })
    }

  }, 600), []);

  useEffect(() => {
    makeDebounceSearch(searchKey, searchValue);
  }, [searchValue])

  return (
    <Fragment>
      <Row className="mb-2">
        {iscustomPageSizeOptions &&
          <Col md={customPageSizeOptions ? 3 : 2}>
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 25, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        }

        {hasSearch &&
          <Col md={4}>
            <Form
              autoComplete="off"
              {...searchByNames && {
                initialValues: {
                  searchByName: searchByNames?.[0]?.value,
                },
              }}
            >
              <Row>
                {searchByNames && (
                  <Col span={12}>
                    <FormGroup>
                      <Input type="select" name="searchByName" onChange={e => {
                        setSearchKey(e.target.value)
                      }} className="search-selector" placeholder="Search by:" style={{ width: '100%', borderRadius: '16px' }}>
                        <option key="0" value="">
                          {`Select Search Field`}
                        </option>
                        {searchByNames.map(({ value, text }) => (
                          <option key={value} value={value}>
                            {`Search by: ${text}`}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                )}
                <Col span={12}>
                  <FormGroup>
                    <Input name="searchData" placeholder="Search"
                      value={searchValue}
                      onChange={e => {
                        !searchKey && setSearchKey(searchByNames?.[0]?.value);
                        setSearchValue(e.target.value);
                      }}
                      // onKeyPress={(e) => {
                      //   if (e.key === 'Enter') {
                      //     handleSubmit(e);
                      //   }
                      // }}
                      style={{ borderRadius: '16px' }} />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        }
        {/* {!completeTransaction && (
          <Col sm="5" />
        )} */}
        {completeTransaction && (
          <Col sm="4">
            <div className="text-end">
              <Button
                type="button"
                color="primary"
                className="btn-rounded mb-2 me-2"
                onClick={onCompleteTransaction}
              >
                <i className="mdi mdi-plus me-1" />
                Add Payment
              </Button>
            </div>
          </Col>
        )}
        {hasCreate && (
          <Col sm="4">
            <div className="text-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded me-2"
                onClick={onCreate}
              >
                <i className="mdi mdi-plus me-1" />
                {addText}
              </Button>
            </div>
          </Col>
        )}
        {/* {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )} */}
        {/* {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )} */}
      </Row>

      <div className="table-responsive">
        {loading ? <Spinners />
          :
          <Table {...getTableProps()} className={tableClass}>
            <thead className={theadClass}>
              {headerGroups.map(headerGroup => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th key={column.id} className={column.isSort ? "sorting" : ''}>
                      <div className="m-0" {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                      </div>
                      {/* <Filter column={column} /> */}
                    </th>
                  ))}
                  <th className={''}>
                    <div className="m-0">
                      {"ACTIONS"}
                    </div>
                  </th>
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                      <td>
                        <div className="d-flex gap-3">
                          {
                            showEdit &&
                            <Button
                              color="link"
                              className="text-success p-0 m-0"
                              onClick={() => onEdit(row)}
                            >
                              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                              <UncontrolledTooltip placement="top" target="edittooltip">
                                Edit
                              </UncontrolledTooltip>
                            </Button>
                          }
                          {
                            showView && <Button
                              color="link"
                              className="text-primary p-0 m-0"
                              onClick={() => onView(row)}
                            >
                              <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                              <UncontrolledTooltip placement="top" target="viewtooltip">
                                View
                              </UncontrolledTooltip>
                            </Button>
                          }
                          {
                            showDelete &&
                            <Button
                              color="link"
                              className="text-danger p-0 m-0"
                              onClick={() => onDelete(row)}
                            >
                              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                              <UncontrolledTooltip placement="top" target="deletetooltip">
                                Delete
                              </UncontrolledTooltip>
                            </Button>
                          }
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          </Table>
        }
      </div>

      <Pagination>
        <PaginationItem disabled={pageNumber <= 0}>
          <PaginationLink first onClick={() => paginate(1)} />
        </PaginationItem>
        <PaginationItem disabled={pageNumber === 1}>
          <PaginationLink previous onClick={() => paginate(pageNumber - 1)} />
        </PaginationItem>

        {renderPaginationLinks()}

        <PaginationItem disabled={pageNumber === pages}>
          <PaginationLink next onClick={() => paginate(pageNumber + 1)} />
        </PaginationItem>
        <PaginationItem disabled={pageNumber === pages}>
          <PaginationLink last onClick={() => paginate(pages)} />
        </PaginationItem>
      </Pagination>

      {/* {
        isPagination && (
          <Row className="justify-content-between align-items-center">
            {isShowingPageLength && <div className="col-sm">
              <div className="text-muted">Showing <span className="fw-semibold">{page.length}</span> of <span className="fw-semibold">{data.length}</span> entries</div>
            </div>}
            <div className={paginationDiv}>
              <ul className={pagination}>
                <li className={`page-item ${!canPreviousPage ? "disabled" : ''}`}>
                  <Link to="#" className="page-link" onClick={previousPage}>
                    <i className="mdi mdi-chevron-left"></i>
                  </Link>
                </li>
                {pageOptions.map((item, key) => (
                  <React.Fragment key={key}>
                    <li className={pageIndex === item ? "page-item active" : "page-item"}>
                      <Link to="#" className="page-link" onClick={() => gotoPage(item)}>{item + 1}</Link>
                    </li>
                  </React.Fragment>
                ))}
                <li className={`page-item ${!canNextPage ? "disabled" : ''}`}>
                  <Link to="#" className="page-link" onClick={nextPage}>
                    <i className="mdi mdi-chevron-right"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </Row>
        )
      } */}
      <DeleteModal
        id={id}
        show={deleteModal}
        onDeleteClick={deleteResource}
        onCloseClick={() => setDeleteModal(false)}
      />
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
