import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { isEmpty } from "lodash";
import { useTranslation } from 'react-i18next';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  addNewInvoice as onAddNewInvoice,
  updateInvoice as onUpdateInvoice,
} from "store/actions";

import {
  InvoiceId,
  BillingName,
  Total,
  PaymentStatus
}
  from "./InvoiceCol";

//redux
import { useDispatch } from "react-redux";
import InvoicesModal from "./InvoiceModal";

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import { useMutation, useQueryClient } from 'react-query';
import { getAllInvoicesApi } from 'api/transaction';
import { getMemberByIdApi } from 'api/member';
import { useRole } from 'hooks/useRole';
import MainFilter from "components/Common/MainFilter";
import { getInvoicesCountsApi } from 'api/report';
import { getSearchColumns, sumArray } from 'utils/tools';
import { useAuth } from 'hooks/useAuth';

function Invoice() {

  const queryClient = useQueryClient();
  const [pages, setPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const { isFacilitator, isSiteCord, isClusterLeader, isFinance, isDirector, isAdmin, isSuperAdmin, isData } = useRole();

  const { t, i18n } = useTranslation();

  const [addressData, setAddressData] = useState({})

  const dateFormat = 'DD-MM-YYYY';

  const [pagination, setPagination] = useState({
    pageSize: 50,
    pageNumber: 1,
    sortBy: 'id',
    ascendingSort: true,
    wardIds: getSearchColumns()?.find(t => t?.name === 'wardIds')?.value,
    searchColumns: [
      {
        "name": "endDate",
        "value": moment().format(dateFormat)
      },
      {
        "name": "startDate",
        "value": moment().startOf('month').format(dateFormat)
      }
    ]
  });

  const { mutate: mutateInvoiceCounts,
    data: invoicesCountsData } = useMutation(
      (payload) => getInvoicesCountsApi(payload),
      {
        onSuccess: res => {
          // dispatch(setPaymentSummary(res?.data));
        },
        onSettled: () => {
          queryClient.invalidateQueries('/report/application/status/counts');
        },
      }
    );

  const { mutate: mutateInvoices,
    isLoading: isLoadingInvoices,
    data: invoicesData, } = useMutation(
      (payload) => getAllInvoicesApi(payload),
      {
        onSuccess: res => {
          res.pages > 0 && setPages(res.pages);
        },
        onSettled: () => {
          setLoading(false);
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const invoicesCardData = [
    { title: "Total Invoices", iconClass: "bx bx-wallet", description: invoicesCountsData?.data?.length ? sumArray(invoicesCountsData?.data, 'totalInvoices').toLocaleString() : 0 },
    { title: "Successful Invoices", iconClass: "bx bx-check-circle", description: invoicesCountsData?.data?.length ? sumArray(invoicesCountsData?.data, 'successfulInvoices').toLocaleString() : 0 },
    {
      title: "Unsuccessful Invoices",
      iconClass: "bx bx-x-circle",
      description: invoicesCountsData?.data?.length ? sumArray(invoicesCountsData?.data, 'unSuccessfulInvoices').toLocaleString() : 0,
    },
  ];

  //meta title
  document.title = "Invoices | SUN Welfare Administration System";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [invoice, setInvoice] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      invoiceId: (invoice && invoice.invoiceId) || '',
      billingName: (invoice && invoice.billingName) || '',
      invoicedate: (invoice && invoice.invoicedate) || '',
      total: (invoice && invoice.total) || '',
      paymentStatus: (invoice && invoice.paymentStatus) || 'Paid',
      badgeclass: (invoice && invoice.badgeclass) || 'success',
      paymentMethod: (invoice && invoice.paymentMethod) || 'Mastercard',
    },
    validationSchema: Yup.object({
      invoiceId: Yup.string()
        .matches(
          /[0-9\.\-\s+\/()]+/,
          "Please Enter Valid Invoice Id"
        ).required("Please Enter Your Invoice Id"),
      billingName: Yup.string().required("Please Enter Your Billing Name"),
      invoicedate: Yup.string().required("Please Enter Your Invoice Date"),
      total: Yup.string().matches(
        /[0-9\.\-\s+\/()]+/,
        "Please Enter Valid Amount"
      ).required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateInvoice = {
          id: invoice ? invoice.id : 0,
          invoiceId: values.invoiceId,
          billingName: values.billingName,
          invoicedate: values.invoicedate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update invoice
        dispatch(onUpdateInvoice(updateInvoice));
        validation.resetForm();
      } else {
        const newInvoice = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          invoiceId: values["invoiceId"],
          billingName: values["billingName"],
          invoicedate: values["invoicedate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        };
        // save new invoice
        dispatch(onAddNewInvoice(newInvoice));
        validation.resetForm();
      }
      toggle();
    },
  });

  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();

  useEffect(() => {
    mutateInvoices({ ...pagination, pageNumber: pagination.pageNumber - 1 });
  }, [pagination]);

  useEffect(() => {
    mutateInvoiceCounts({
      fromDate: moment().startOf('month').format('DD-MM-YYYY'),
      toDate: moment().format('DD-MM-YYYY'),
      wardIds: getSearchColumns()?.find(t => t.name === 'wardIds')?.value,
      address: addressData
    });
  }, [addressData]);

  const onChangePagination = (
    { page, limit }, filters, sorter) => {
    let searchColumns = isEmpty(filters) ? [] : [
      filters?.id && {
        id: filters?.id
      },
      filters?.applicationNumber && {
        applicationNumber: filters?.applicationNumber
      }
    ];
    setPagination({ ...pagination, pageNumber: page || pagination.pageNumber, pageSize: limit || pagination.pageSize, searchColumns })
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      setInvoice(null);
    } else {
      setModal(true);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => <div className="form-check font-size-16" >
          <input className="form-check-input" type="checkbox" id="checkAll" />
          <label className="form-check-label" htmlFor="checkAll"></label>
        </div>,
        accessor: '#',
        width: '20px',
        filterable: true,
        Cell: (cellProps) => (
          <div className="form-check font-size-16" >
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        )
      },
      {
        Header: 'INVOICE NUMBER',
        accessor: 'invoiceNumber',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <InvoiceId {...cellProps} />;
        }
      },
      {
        Header: 'POLICY NUMBER',
        accessor: 'policyNumber',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        }
      },
      {
        Header: 'REPORTED DATE',
        accessor: 'dueDate',
        filterable: true,
        Cell: (cellProps) => {
          const formattedDate = moment(cellProps.value).format('YYYY MMM DD h:mm A');
          return <span>{formattedDate}</span>;
        }
      },
      {
        Header: 'MEMBER NAME',
        accessor: 'toMemeberId',
        filterable: true,
        Cell: (cellProps) => {
          const [memberDetails, setMemberDetails] = useState(null);

          useEffect(() => {
            const fetchMemberDetails = async () => {
              const memberId = cellProps.row.original.toMemeberId;
              if (memberId) {
                try {
                  const details = await getMemberByIdApi(memberId);
                  setMemberDetails(details);
                } catch (error) {
                  console.error('Error fetching member details:', error);
                }
              }
            };

            fetchMemberDetails();
          }, [cellProps.row.original.toMemeberId]);

          return <span>{memberDetails ? `${memberDetails.firstName} ${memberDetails.lastName}` : ''}</span>;
        },
      },
      {
        Header: 'AMOUNT',
        accessor: 'totalAmount',
        filterable: true,
        Cell: (cellProps) => {
          return <Total {...cellProps} />;
        }
      },
      {
        Header: 'STATUS',
        accessor: 'status',
        Cell: (cellProps) => {
          return <PaymentStatus {...cellProps} />;
        }
      },
    ],
    []
  );

  const onSearch = (data) => {
    const searchColumns = [];
    if (!isEmpty(data)) {
      if (isFacilitator) {
        searchColumns.push({ name: "wardId", value: address?.wardId });
      }
      if (isSiteCord && data.wardId) {
        searchColumns.push(data?.wardId);
      } else {
        if ((isClusterLeader || isFinance || isDirector || isAdmin || isSuperAdmin) && data?.regionId) {
          searchColumns.push({ name: "regionId", value: data?.regionId });
        }
        if (data.wardId) {
          searchColumns.push(data?.wardId);
        }
        if (data.constituencyId) {
          searchColumns.push(data?.constituencyId);
        }
        if (data.countyId) {
          searchColumns.push(data?.countyId);
        }
      }
      data.startDate && searchColumns.push(data.startDate);
      data.endDate && searchColumns.push(data.endDate);
    }

    setPagination({ ...pagination, searchColumns });
    let add = {}
    searchColumns.forEach((a, i) => {
      add[a.name] = a.value;
    });
    setAddressData(add);
  }

  return (
    <React.Fragment>
      <InvoicesModal isOpen={modal1} toggle={toggleViewModal} />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Invoices" />
          <Row>
            {/* Reports Render */}
            {invoicesCardData.map((report, key) => (
              <Col md="4" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                          {report.title}
                        </p>
                        <h4 className="mb-0">{report.description}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i
                            className={
                              "bx " + report.iconClass + " font-size-24"
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <MainFilter onSearch={onSearch} showDateFilter />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={invoicesData || []}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    hasCreate={!isData}
                    isPagination={true}
                    filterable={false}
                    iscustomPageSizeOptions={true}
                    // showDelete
                    // showEdit
                    loading={isLoadingInvoices}
                    showView
                    pages={pages}
                    addText="Send Invoice"
                    resource="invoice"
                    tableClass="align-middle table-nowrap table-check"
                    theadClass="table-light"
                    pagination="pagination pagination-rounded justify-content-end mb-2"
                    paginationData={pagination}
                    onChangePagination={onChangePagination}
                    searchByNames={[
                      {
                        value: 'email',
                        text: t('common.email'),
                      },
                      {
                        value: 'idNumber',
                        text: t('common.idNumber'),
                      },
                      {
                        value: 'applicationNumber',
                        text: t('applications.applicationNumber'),
                      }
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Invoice" : "Add Invoice"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Invoice Id</Label>
                      <Input
                        name="invoiceId"
                        type="text"
                        placeholder="Insert Invoice Id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.invoiceId || ""}
                        invalid={
                          validation.touched.invoiceId && validation.errors.invoiceId ? true : false
                        }
                      />
                      {validation.touched.invoiceId && validation.errors.invoiceId ? (
                        <FormFeedback type="invalid">{validation.errors.invoiceId}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Billing Name</Label>
                      <Input
                        name="billingName"
                        type="text"
                        placeholder="Insert Billing Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.billingName || ""}
                        invalid={
                          validation.touched.billingName && validation.errors.billingName ? true : false
                        }
                      />
                      {validation.touched.billingName && validation.errors.billingName ? (
                        <FormFeedback type="invalid">{validation.errors.billingName}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Invoice Date</Label>
                      <Flatpickr
                        className="form-control d-block"
                        name="invoicedate"
                        placeholder="Select time"
                        options={{
                          dateFormat: 'd M, Y',
                        }}
                        onChange={(date) => validation.setFieldValue("invoicedate", moment(date[0]).format("DD MMMM, YYYY"))}
                        value={validation.values.invoicedate}
                      />
                      {validation.touched.invoicedate && validation.errors.invoicedate ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.invoicedate}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Total</Label>
                      <Input
                        name="total"
                        type="text"
                        placeholder="Insert Total Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.total || ""}
                        invalid={
                          validation.touched.total && validation.errors.total ? true : false
                        }
                      />
                      {validation.touched.total && validation.errors.total ? (
                        <FormFeedback type="invalid">{validation.errors.total}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Status</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.paymentStatus || ""
                        }
                      >
                        <option>Paid</option>
                        <option>Chargeback</option>
                        <option>Refund</option>
                      </Input>
                      {validation.touched.paymentStatus && validation.errors.paymentStatus ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.paymentStatus}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Badge Class</Label>
                      <Input
                        name="badgeclass"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.badgeclass || ""}
                      >
                        <option>success</option>
                        <option>danger</option>
                        <option>warning</option>
                      </Input>
                      {validation.touched.badgeclass && validation.errors.badgeclass ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.badgeclass}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Method</Label>
                      <Input
                        name="paymentMethod"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.paymentMethod || ""
                        }
                      >
                        <option>Mastercard</option>
                        <option>Visa</option>
                        <option>Paypal</option>
                        <option>COD</option>
                      </Input>
                      {validation.touched.paymentMethod && validation.errors.paymentMethod ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.paymentMethod}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
Invoice.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default Invoice;
