import React, { useEffect, useState } from 'react';
import {
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Alert,
    FormFeedback,
    Button
} from "reactstrap";
import moment from 'moment'
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { updateMember, addMember } from 'api/member';
import { getAllRegionsApi, getAllCountiesByRegionApi, getConstituencyApi, getAllWardsApi, getAllRelationshipsApi } from 'api/report';
import { getAllGroupApi } from 'api/group';
import { getSearchColumns, sumArray } from 'utils/tools';
import { isEmpty } from 'lodash';
import { useRole } from 'hooks/useRole';
import Select from 'react-select';


const MemberDetailsTab = ({ onNext, address, member, onChangeMemberId = () => { }, onSetMember, mutateMember }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [errorMessage, setErrorMessage] = useState(null);
    const isNew = isEmpty(id);
    const queryClient = useQueryClient();

    const [regions, setRegions] = useState([]);
    const [counties, setCounties] = useState([]);
    const [groups, setGroups] = useState([]);
    const [wards, setWards] = useState([]);
    const [subCounties, setSubCounties] = useState([]);

    const { isFacilitator, isSiteCord, isCountyCoordinator, isClusterLeader, addresses } = useRole();

    // Define validation schema
    const validationSchema = Yup.object({
        firstName: Yup.string().required("Please Enter First Name"),
        lastName: Yup.string().required("Please Enter Last Name"),
        identificationType: Yup.string().required("Please Select ID Type"),
        identificationNumber: Yup.string()
            .required("Please Enter ID")
            .max(9, "ID cannot be more than 9 digits"),
        mobileNumber1: Yup.string(
        ).required("Please Enter Mobile Number")
            .matches(/^\d+$/, "Phone number must contain only digits"),
        gender: Yup.string().required("Please Select Gender"),
        dob: Yup.string().required("Please Select DOB"),
        joinType: Yup.string().required("Please Select Joining Type"),
        membership: Yup.string().required("Please Select Joining Type"),
        groupId: Yup.string().when('joinType', {
            is: (v) => v === 'Group',
            then: (schema) => schema.required('Please Select Group')
        })
    });

    // Initialize formik
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: member?.firstName || '',
            lastName: member?.lastName || '',
            email: member?.email || '',
            identificationType: member?.identificationType || '',
            identificationNumber: member?.identificationNumber || '',
            mobileNumber1: member?.mobileNumber1 || '',
            mobileNumber2: member?.mobileNumber2 || '',
            gender: member?.gender || '',
            dob: member?.dob ? moment(member?.dob).format('DD-MM-YYYY') : '',
            joinType: member?.joinType || '',
            groupId: member?.groupId || '',
            showGroup: false,
            membership: member?.membership || 'New',
            groupId: member?.groupId || '',
            regionId: member?.addresses?.[0].regionId || '',
            countyId: member?.addresses?.[0].countyId || '',
            constituencyId: member?.addresses?.[0].constituencyId || '',
            wardId: member?.addresses?.[0].wardId || ''
        },
        validationSchema,
        onSubmit: async ({ dob, ...values }) => {
            let memberId = null;
            if (!(member?.id || member?.memberId)) {
                const data = {
                    ...values,
                    dob: moment(dob).format("DD-MM-YYYY"),
                    source: member?.source || "Web",
                    status: 'Draft',
                    address
                }
                await addMember(data).then(res => {
                    memberId = res?.data;
                    mutateMember(memberId);
                    onNext();
                }).catch(err => {
                    setErrorMessage(err?.message)
                });
                // onSetMember(data);
            } else {
                const data = {
                    ...member,
                    ...values,
                    memberId: member?.memberId,
                    dob: moment(dob).format("DD-MM-YYYY"),
                    source: member?.source || "Web",
                    status: 'Draft',
                    address,
                }
                await updateMember(data).then(res => {
                    memberId = res.data?.id;
                    onNext();
                }).catch(err => {
                    setErrorMessage(err?.message)
                });
            }
            onChangeMemberId(memberId);
        },
    });

    const { mutate: mutateGroups, isLoading: isLoadingGroups, data: groupsData } = useMutation(
        (payload) => getAllGroupApi(payload),
        {
            onSuccess: res => {
            },
            onSettled: () => {
                queryClient.invalidateQueries('get-all-counties');
            },
        }
    );

    const groupOptions = groupsData?.list?.map(item => ({
        value: item.id,
        label: item.name
    })) || [];

    const { mutate: mutateRegions, isLoading: isLoadingRegions, data: regionsData } = useMutation(
        (payload) => getAllRegionsApi(payload),
        {
            onSuccess: res => {
                let regionIds = member?.addresses?.map(r => r?.regionId) || addresses?.map(r => r?.regionId)
                setRegions(res?.filter(r => regionIds?.includes(r.id)));
            },
            onSettled: () => {
                queryClient.invalidateQueries('get-all-counties');
            },
        }
    );

    const { mutate: mutateCounty, isLoading: isLoadingCounty, data: countiesData } = useMutation(
        (payload) => getAllCountiesByRegionApi(payload),
        {
            onSuccess: res => {
                let countyIds = addresses?.map(r => r?.countyId);
                // Step 1: Collect all new data that isn't already in the state
                const newData = res?.filter(r => countyIds?.includes(r.id))?.filter(c => !counties.some(item => item.id === c.id));

                // Step 2: Only update the state if there's new data to add
                if (newData?.length > 0) {
                    setCounties(prevData => [...prevData, ...newData]);
                }
            },
            onSettled: () => {
                queryClient.invalidateQueries('get-all-counties');
            },
        }
    );

    const { mutate: mutateSubCounty, isLoading: isLoadingSubCounty, data: subCountiesData } = useMutation(
        (payload) => getConstituencyApi(payload),
        {
            onSuccess: res => {
                let scountyIds = addresses?.map(r => r?.constituencyId)
                // Step 1: Collect all new data that isn't already in the state
                const newData = res?.filter(r => scountyIds?.includes(r.id))?.filter(c => !subCounties.some(item => item.id === c.id));

                // Step 2: Only update the state if there's new data to add
                if (newData?.length > 0) {
                    setSubCounties(prevData => [...prevData, ...newData]);
                }
            },
            onSettled: () => {
                queryClient.invalidateQueries('get-all-counties');
            },
        }
    );

    const { mutate: mutateWards, isLoading: isLoadingWards, data: wardsData } = useMutation(
        (payload) => getAllWardsApi(payload),
        {
            onSuccess: res => {
                let wardIds = addresses?.map(r => r?.wardId)
                // Step 1: Collect all new data that isn't already in the state
                const newData = res?.filter(r => wardIds?.includes(r.id))?.filter(c => !wards.some(item => item.id === c.id));

                // Step 2: Only update the state if there's new data to add
                if (newData?.length > 0) {
                    setWards(prevData => [...prevData, ...newData]);
                }
            },
            onSettled: () => {
                queryClient.invalidateQueries('get-all-counties');
            },
        }
    );

    useEffect(() => {
        if (member) {
            mutateRegions({
                countryId: 1
            });
            const uniqueRegionIds = [...new Set(addresses?.map(a => a.regionId))];
            const uniqueCountyIds = [...new Set(addresses?.map(a => a.countyId))];
            const uniqueSubCountyIds = [...new Set(addresses?.map(a => a.constituencyId))];
            const uniqueWardIds = [...new Set(addresses?.map(a => a.wardId))];
            let countyActions = uniqueRegionIds?.map(id => mutateCounty({ regionId: id }));
            Promise.all(countyActions);
            let subCountyActions = uniqueCountyIds?.map(id => mutateSubCounty({ countyId: id }));
            Promise.all(subCountyActions);
            let wardActions = uniqueSubCountyIds?.map(id => mutateWards({ constituencyId: id }));
            Promise.all(wardActions);
            // let groupActions = uniqueWardIds?.map(id => mutateWards({ constituencyId: id }));
            // Promise.all(groupActions);
            mutateGroups({
                pageSize: 2000,
                pageNumber: 0,
                sortBy: "name",
                wardIds: getSearchColumns()?.find(t => t?.name === 'wardIds')?.value,
                searchColumns: []
            });
            onChangeMemberId(member.memberId);
        }
        if (member?.memberId) {
            onChangeMemberId(member?.memberId);
        }
    }, [member]);



    const onChangeRegion = e => {
        const regionId = e.target.value;
        validation.setFieldValue('regionId', regionId);
        onChangeAddress({ regionId });
        mutateCounty({ regionId });
    }

    const onChangeCounty = e => {
        const countyId = e.target.value;
        validation.setFieldValue('countyId', countyId);
        onChangeAddress({ countyId });
        mutateSubCounty({ countyId });
    }

    const onChangeSubCounty = e => {
        const constituencyId = e.target.value;
        validation.setFieldValue('constituencyId', constituencyId);
        onChangeAddress({ constituencyId });
        mutateWards({ constituencyId });
    }

    const onChangeWard = e => {
        const wardId = e.target.value;
        validation.setFieldValue('wardId', wardId);
        onChangeAddress({ wardId });
        mutateGroups({
            pageSize: 1000,
            pageNumber: 0,
            sortBy: "name",
            wardIds: getSearchColumns()?.find(t => t?.name === 'wardIds')?.value,
            searchColumns: [{ "name": "wardId", "value": wardId }]
        });
    }

    return (
        <Form autoComplete="off" onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
        }}>
            <Row>
                {errorMessage && errorMessage ? (
                    <Alert color="danger">{errorMessage}</Alert>
                ) : null}
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for="firstName">
                            First Name
                        </Label>
                        <Input
                            name="firstName"
                            type="text"
                            className="form-control"
                            id="firstName"
                            placeholder="Enter Your First Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName || ""}
                            invalid={
                                validation.touched.firstName && validation.errors.firstName ? true : false
                            }
                        />
                        {validation.touched.firstName && validation.errors.firstName ? (
                            <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for="lastName">
                            Last Name
                        </Label>
                        <Input
                            name="lastName"
                            type="text"
                            className="form-control"
                            id="lastName"
                            placeholder="Enter Your Last Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName || ""}
                            invalid={
                                validation.touched.lastName && validation.errors.lastName ? true : false
                            }
                        />
                        {validation.touched.lastName && validation.errors.lastName ? (
                            <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for='identificationType'>Identification Type</Label>
                        <Input type='select' id='identificationType' className="form-select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="identificationType"
                            value={validation.values.identificationType || ""}
                            invalid={
                                validation.touched.identificationType && validation.errors.identificationType ? true : false
                            }

                        >
                            <option defaultValue>
                                Select ID Type...
                            </option>
                            <option value="idNumber">National ID Card</option>
                            <option value="Passport">Passport</option>
                        </Input>
                        {validation.touched.identificationType && validation.errors.identificationType ? (
                            <FormFeedback type="invalid">{validation.errors.identificationType}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for="identificationNumber">
                            Identification Number
                        </Label>
                        <Input
                            name="identificationNumber"
                            type="text"
                            className="form-control"
                            id="identificationNumber"
                            placeholder="Enter Your Identification Number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.identificationNumber || ""}
                            invalid={
                                validation.touched.identificationNumber && validation.errors.identificationNumber ? true : false
                            }
                        />
                        {validation.touched.identificationNumber && validation.errors.identificationNumber ? (
                            <FormFeedback type="invalid">{validation.errors.identificationNumber}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for="dob">
                            Date Of birth
                        </Label>
                        <Input
                            name="dob"
                            max={moment().subtract(18, "years").format("DD-MM-YYYY")}
                            type="date"
                            className="form-control"
                            id="dob"
                            placeholder="Select Date of Birth"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dob || ""}
                            invalid={
                                validation.touched.dob && validation.errors.dob ? true : false
                            }
                        />
                        {validation.touched.dob && validation.errors.dob ? (
                            <FormFeedback type="invalid">{validation.errors.dob}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for='gender'>Sex</Label>
                        <Input type='select' className="form-select"
                            name="gender"
                            id='gender'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.gender || ""}
                            invalid={
                                validation.touched.gender && validation.errors.gender ? true : false
                            }
                        >
                            <option value="" disabled>
                                Select Gender...
                            </option>
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                            <option value="Intersex">Intersex</option>
                            <option value="Other">Other</option>
                        </Input>
                        {validation.touched.gender && validation.errors.gender ? (
                            <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for="mobileNumber1">
                            Phone Number
                        </Label>
                        <Input
                            name="mobileNumber1"
                            type="text"
                            className="form-control"
                            id="mobileNumber1"
                            placeholder="Enter Your Mobile Number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mobileNumber1 || ""}
                            invalid={
                                validation.touched.mobileNumber1 && validation.errors.mobileNumber1 ? true : false
                            }
                        />
                        {validation.touched.mobileNumber1 && validation.errors.mobileNumber1 ? (
                            <FormFeedback type="invalid">{validation.errors.mobileNumber1}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for="mobileNumber2">
                            Alternate Phone Number
                        </Label>
                        <Input
                            name="mobileNumber2"
                            type="number"
                            className="form-control"
                            id="mobileNumber2"
                            placeholder="Enter Alternate Mobile Number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mobileNumber2 || ""}

                        />
                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for="email">
                            Enter Email Address
                        </Label>
                        <Input
                            name="email"
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Enter Email Address"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                        />

                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for='joinType'>Joining Type</Label>
                        <Input
                            type='select'
                            className="form-select"
                            name="joinType"
                            id='joinType'
                            onChange={(e) => {
                                const value = e.target.value;
                                validation.setFieldValue('joinType', value);

                                if (value === "Group") {
                                    validation.setFieldValue('showGroup', true);
                                    mutateGroups({
                                        pageSize: 2000,
                                        pageNumber: 0,
                                        sortBy: "name",
                                        wardIds: getSearchColumns()?.find(t => t?.name === 'wardIds')?.value,
                                        searchColumns: []
                                    });

                                } else {
                                    validation.setFieldValue('showGroup', false);
                                }
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.joinType || ""}
                            invalid={validation.touched.joinType && validation.errors.joinType ? true : false}
                        >
                            <option value="" defaultValue>
                                Select an option...
                            </option>
                            <option value="Individual">Indivudual</option>
                            <option value="Group">Group</option>
                        </Input>
                        {validation.touched.joinType && validation.errors.joinType ? (
                            <FormFeedback type="invalid">{validation.errors.joinType}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for='membership'>Membership</Label>
                        <Input type='select' className="form-select"
                            name="membership"
                            id='membership'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.membership || ""}
                            invalid={
                                validation.touched.membership && validation.errors.membership ? true : false
                            }
                        >
                            <option value="" defaultValue>
                                Select an option...
                            </option>
                            <option value="New">New</option>
                            <option value="Old">Old</option>
                        </Input>
                        {validation.touched.membership && validation.errors.membership ? (
                            <FormFeedback type="invalid">{validation.errors.membership}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </Col>
                {validation.values.joinType === "Group" && (
                    <Col lg="6">
                        <FormGroup className="mb-3">
                            <Label for='groupId'>Select Group</Label>
                            <Select
                                id='groupId'
                                name="groupId"
                                options={groupOptions}
                                value={groupOptions.find(option => option.value === validation.values.groupId)}
                                onChange={(selectedOption) => validation.setFieldValue("groupId", selectedOption?.value)}
                                onBlur={validation.handleBlur}
                                className={validation.touched.groupId && validation.errors.groupId ? 'is-invalid' : ''}
                            />
                            {validation.touched.groupId && validation.errors.groupId && (
                                <FormFeedback type="invalid">{validation.errors.groupId}</FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                )}
            </Row>
            <Row>
                <Col lg="3">
                    <FormGroup className="mb-3">
                        <Label for='regionId'>Select Region</Label>
                        <Input type='select' className="form-select"
                            name="regionId"
                            id='regionId'
                            value={validation.values.regionId || ""}
                            onChange={onChangeRegion}>
                            <option defaultValue value={''}>
                                Select Region...
                            </option>
                            {
                                regions?.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                )
                                )
                            }
                        </Input>
                    </FormGroup>
                </Col>
                <Col lg="3">
                    <FormGroup className="mb-3">
                        <Label for='countyId'>Select County</Label>
                        <Input type='select' className="form-select"
                            id='countyId'
                            name="countyId"
                            value={validation.values.countyId || ""}
                            onChange={onChangeCounty}>
                            <option defaultValue value={''}>
                                Select County...
                            </option>
                            {
                                counties?.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                )
                                )
                            }
                        </Input>
                    </FormGroup>
                </Col>
                <Col lg="3">
                    <FormGroup className="mb-3">
                        <Label for='constituencyId'>Select Constituency</Label>
                        <Input type='select' className="form-select"
                            name="constituencyId"
                            id='constituencyId'
                            value={validation.values.constituencyId || ""}
                            onChange={onChangeSubCounty}>
                            <option defaultValue value={''}>
                                Select Constituency...
                            </option>
                            {
                                subCounties?.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                )
                                )
                            }
                        </Input>
                    </FormGroup>
                </Col>
                <Col lg="3">
                    <FormGroup className="mb-3">
                        <Label for='wardId'>Select Ward</Label>
                        <Input type='select' className="form-select"
                            id='wardId'
                            name="wardId"
                            value={validation.values.wardId || ""}
                            onChange={onChangeWard}>
                            <option defaultValue value={''}>
                                Select Ward...
                            </option>
                            {
                                wards?.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                )
                                )
                            }
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md="6">
                    <FormGroup>
                        <Button
                            type="submit"
                            color="primary"
                            block
                        >
                            SAVE DRAFT
                        </Button>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    )
}

export default MemberDetailsTab
